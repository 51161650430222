.dashboardMainBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  min-height: 100vh;
  .dashboardLeftBlock {
    min-width: 200px;
    padding: 17px;
    z-index: 9;
    @media (max-width: 1400px) {
      min-width: 256px;
    }
    @media (max-width: 1100px) {
      min-width: unset;
      width: 60px;
      padding: 0;
    }
  }
  .dashboardRightBlock {
    width: calc(100% - 200px);
    @media (max-width: 1400px) {
      width: calc(100% - 256px);
    }
    @media (max-width: 1100px) {
      width: calc(100% - 60px);
    }
  }
}

.dashboardContant {
  background-color: rgba(60, 100, 177, 0.06);
  padding: 68px 36px;
  min-height: calc(100vh - 84px);
  @media (max-width: 767px) {
    padding: 30px 30px;
  }
  @media (max-width: 480px) {
    padding: 30px 20px;
  }
  .dashHeading {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 0.2px;
    color: #25282b;
    margin-bottom: 16px;
    @media (max-width: 767px) {
      font-size: 30px;
      margin-bottom: 5px;
    }
    @media (max-width: 480px) {
      font-size: 24px;
      line-height: normal;
    }
  }
  .dashSubHeading {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-bottom: 42px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 23px;
    }
  }
  .dashWhatsNew1 {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: #fbfcfe;
    position: relative;
    @media (max-width: 1100px) {
      flex-wrap: wrap;
    }
  
    .imageSection {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1100px) {
        width: 100%;
        padding: 40px;
      }
    }
    .textSetion {
      width: 50%;
      padding: 38px;     
      @media (max-width: 1100px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        padding: 20px;
      }
      h6 {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #828282;
        margin-bottom: 12px;
      }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 56px;
        letter-spacing: 0.2px;
        color: #373f41;
        margin-bottom: 40px;
        max-width: 400px;
        @media (max-width: 1100px) {
          max-width: unset;
        }
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: normal;
          margin-bottom: 17px;
        }
        @media (max-width: 480px) {
          font-size: 23px;
        }
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-bottom: 68px;
        max-width: 440px;
        z-index: 1;
        @media(max-width:991px){
            font-size: 14px;
            margin-bottom: 30px;
        }
      }
         
      
    }
  }  

  .dashWhatsNew {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: #fbfcfe;
    position: relative;
    @media (max-width: 1100px) {
      flex-wrap: wrap;
    }
    &::after {
      content: "";
      background-image: url("../../images/lines.svg");
      background-position: center;
      background-size: cover;
      display: block;
      z-index: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::before {
      content: "";
      background-image: url("../../images/lines2.svg");
      background-position: center;
      background-size: cover;
      display: block;
      z-index: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .imageSection {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1100px) {
        width: 100%;
        padding: 40px;
      }
    }
    .textSetion {
      width: 50%;
      padding: 38px;
      @media (max-width: 1100px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        padding: 20px;
      }
      h6 {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #828282;
        margin-bottom: 12px;
      }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 56px;
        letter-spacing: 0.2px;
        color: #373f41;
        margin-bottom: 40px;
        max-width: 400px;
        @media (max-width: 1100px) {
          max-width: unset;
        }
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: normal;
          margin-bottom: 17px;
        }
        @media (max-width: 480px) {
          font-size: 23px;
        }
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.3px;
        color: #737b7d;
        margin-bottom: 60px;
        max-width: 445px;
        @media (max-width: 1100px) {
          max-width: unset;
        }
        @media (max-width: 480px) {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.pdfViewrBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding-top: 88px;
  position: relative;
  @media (max-width: 767px) {
    padding-top: 180px;
  }
  .mgrpdf-navigation__controls--wrapper {
    display: flex !important;
    align-items: flex-end;
    justify-content: center;
    background-color: transparent !important;
    color: #000 !important;
    height: 100%;
    .mgrpdf-navigation__controls--previous {
      text-align: center;
      margin-left: 12px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: #c4c4c4;
      mix-blend-mode: multiply;
      border-radius: 31px;
      height: 48px;
      width: 48px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      font-size: 22px;
    }
    .mgrpdf-navigation__controls--next {
      text-align: center;
      margin-right: 12px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: #c4c4c4;
      mix-blend-mode: multiply;
      border-radius: 31px;
      height: 48px;
      width: 48px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      font-size: 22px;
    }
    .mgrpdf-navigation__controls--pages {
      background: #c4c4c4;
      mix-blend-mode: multiply;
      border-radius: 46px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      padding: 8px 15px;
      margin-bottom: 15px;
    }
  }
}
.pdfHeader {
  background: #f6f5f5;
  position: fixed;
  opacity: 0.7;
  top: 0;
  z-index: 99;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px;
  @media (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
  }
  .pdfName {
    display: flex;
    align-items: center;
  }
  .pdfNameBlock {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #010000;
    padding-left: 20px;
    max-width: 504px;
    @media (max-width: 991px) {
      font-size: 18px;
      max-width: 400px;
    }
    p {
      margin-bottom: 0;
      padding-bottom: 8px;
    }
    .pdfInfoBlock {
      display: flex;
      flex-wrap: wrap;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.2px;
        padding-bottom: 5px;
        margin-right: 15px;
        @media (max-width: 991px) {
          font-size: 13px;
          margin-right: 10px;
        }
      }
    }
  }
}
.pdfBtns {
  margin-left: 20px;
  @media (max-width: 767px) {
    margin-left: 10px;
    padding-top: 10px;
  }
  .pdfDownlode {
    background-color: #4f4f4f;
    border-radius: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    border: 1px solid #4f4f4f;
    display: flex;
    align-items: center;
    padding: 7px 23px;
    img {
      margin-right: 5px;
    }
    @media (max-width: 1024px) {
      font-size: 0px;
      img {
        margin-right: 0px;
      }
    }
  }
  .pdfPrint {
    background-color: #4f4f4f;
    border-radius: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    border: 1px solid #4f4f4f;
    display: flex;
    align-items: center;
    padding: 7px 23px;
    margin-left: 10px;
    img {
      margin-right: 5px;
    }
    @media (max-width: 1024px) {
      font-size: 0px;
      img {
        margin-right: 0px;
      }
    }
  }
}

